/**
 * Force re-render App.vue.
 */

export default {
  methods: {
    forceRerenderApp: function(content, navigation) {
      // App.vue
      this.$root.$children[0].forceRerender(content, navigation);
    }
  }
};
