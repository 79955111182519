<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer></v-spacer>
      <v-tooltip top v-if="isAdmin">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              createDialog = true;
              expanded = [];
            "
            :disabled="loading || createDialog"
            icon
          >
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add user") }}</span>
      </v-tooltip>
      <wiki slug="user-management" />
    </v-toolbar>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="users"
            :options.sync="options"
            :loading="loading"
            :mobile-breakpoint="mbreakpoint"
            :search="search"
            show-expand
            single-expand
            sort-by="email"
            :hide-default-footer="users.length <= 15"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageOptions: [15, 50],
            }"
            :expanded.sync="expanded"
            @item-expanded="createDialog = false"
          >
            <template v-slot:top>
              <div>
                <v-expand-transition>
                  <user-details
                    class="mb-4"
                    v-if="createDialog"
                    @userCreated="createUser"
                    @userCanceled="createDialog = false"
                    v-bind.sync="newItem"
                    :apiRolesChoices="apiRolesChoices"
                    :contactRolesChoices="contactRolesChoices"
                  />
                </v-expand-transition>
              </div>
            </template>
            <template v-slot:item.type="{ item }">
              {{ $t(item.type + "-name") }}
            </template>
            <template v-slot:item.last_login="{ item }">
              <div class="monospace">
                {{
                  item.last_login === null
                    ? ""
                    : formatDateTime(item.last_login)
                }}
              </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <user-details
                  class="mb-4 mt-4"
                  @userCreated="getUsers"
                  @userUpdated="updateUser(item)"
                  @userDeleted="getUsers"
                  v-bind.sync="item"
                  :apiRolesChoices="apiRolesChoices"
                  :contactRolesChoices="contactRolesChoices"
                />
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";
import UserDetails from "@/components/settings/UserDetails";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "UserManager",
  mixins: [formatDateTime, isMobile],
  components: {
    UserDetails,
    Wiki,
  },
  data: () => ({
    search: "",
    users: [],
    loading: false,
    options: {},
    contactRolesChoices: [],
    apiRolesChoices: [],
    newItem: {},
    createDialog: false,
    expanded: [],
  }),
  watch: {
    options: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    headers() {
      return [
        {
          text: this.$i18n.t("first name"),
          value: "first_name",
        },
        {
          text: this.$i18n.t("last name"),
          value: "last_name",
        },
        {
          text: this.$i18n.t("email"),
          value: "email",
        },
        {
          text: this.$i18n.t("type"),
          value: "type",
        },
        {
          text: this.$i18n.t("last login"),
          value: "last_login",
        },
        {
          text: "",
          value: "data-table-expand",
        },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.getUsers();
    },
  },
  methods: {
    getUsers: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("persons")
        .then((response) => {
          that.users = response.data;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    createUser: function () {
      this.createDialog = false;
      this.newItem = {};
      this.getUsers();
    },
    updateUser: function (updatedItem) {
      this.expanded = this.expanded.filter(function (item) {
        return updatedItem.id === item.id;
      });
    },
    getApiRoleChoices: function () {
      var that = this;
      this.$http
        .get("persons/api_roles")
        .then((response) => {
          that.apiRolesChoices = response.data;
        })
        .catch((errror) => {});
    },
    getContactRoleChoices: function () {
      var that = this;
      this.$http
        .get("persons/contact_roles")
        .then((response) => {
          that.contactRolesChoices = response.data;
        })
        .catch((error) => {});
    },
  },
  mounted() {
    this.getUsers();
    this.getApiRoleChoices();
    this.getContactRoleChoices();
  },
};
</script>

<i18n>
{
  "en": {
    "user manager": "User manager",
    "first name": "First name",
    "last name": "Last name",
    "phone": "Phone",
    "mobile": "Mobile",
    "email": "Email",
    "last login": "Last login",
    "add user": "Add user",
    "type": "Type",
    "admin-name": "Administrator",
    "full-name": "Full permission user",
    "restricted-name": "Restricted user",
    "login as user": "login as user"
  },
  "de": {
    "user manager": "Benutzerverwaltung",
    "first name": "Vorname",
    "last name": "Nachname",
    "phone": "Telefon",
    "mobile": "Mobil",
    "email": "Email",
    "last login": "letzter Login",
    "add user": "Benutzer hinzufügen",
    "type": "Typ",
    "admin-name": "Administrator",
    "full-name": "voll authorisierter Benutzer",
    "restricted-name": "eingeschränkter Benutzer",
    "login as user": "als Benutzer anmelden"
  }
}
</i18n>
