<template>
  <v-container
    fluid
    v-if="password != null && password.length != '' && score != null"
  >
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          :value="score * 25"
          :color="classColor"
          :height="height"
          rounded
          reactive
        >
          <strong :class="classText">{{ scoreText }} ({{ score }})</strong>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="warning != null || suggestions != null">
      <v-col cols="12">
        <v-alert dense outlined type="error" v-if="warning != null">
          {{ warning }}
        </v-alert>
        <template v-if="suggestions != null">
          <v-alert
            v-for="(suggestion, i) in suggestions"
            :key="i"
            dense
            outlined
            type="info"
          >
            {{ suggestion }}
          </v-alert>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PasswordScore",
  props: {
    password: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 30,
    },
    value: {
      validator: (prop) => typeof prop === "number" || prop === null,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      results: {
        score: this.value,
      },
    };
  },
  computed: {
    score() {
      return this.results.score || 0;
    },
    suggestions() {
      this.results;
      if (
        this.results != null &&
        this.results.feedback != null &&
        this.results.feedback.suggestions != null &&
        this.results.feedback.suggestions.length > 0
      )
        return this.results.feedback.suggestions;
      return null;
    },
    warning() {
      this.results;
      if (
        this.results != null &&
        this.results.feedback != null &&
        this.results.feedback.warning != null &&
        this.results.feedback.warning != ""
      )
        return this.results.feedback.warning;
      return null;
    },
    classColor() {
      return ["error", "error", "error", "warning", "success"][this.score];
    },
    classText() {
      if (this.score != null && this.score <= 1) {
        return "";
      } else {
        return "white--text";
      }
    },
    scoreText() {
      if (this.score != null && this.score >= 0 && this.score <= 4)
        return [
          this.$i18n.t("worst"), // 0
          this.$i18n.t("worst"), // 1
          this.$i18n.t("worse"), // 2
          this.$i18n.t("bad"), // 3
          this.$i18n.t("good"), // 4
        ][this.score];
      else "";
    },
  },
  watch: {
    password: {
      immediate: true,
      handler(value) {
        if (value != null && value.length >= 4) this.pwnd();
        else
          this.results = {
            score: this.value,
          };
      },
    },
  },
  methods: {
    pwnd() {
      var that = this;
      this.$http
        .post("services/pwnd", {
          password: this.password,
        })
        .then(function (response) {
          that.results = response.data;
        })
        .catch(function (error) {
          that.results = { score: 4 };
        })
        .finally(function () {
          that.$emit("input", that.score);
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "score": "Score",
    "worst": "Worst",
    "worse": "Worse",
    "bad": "Bad",
    "good": "Good"
  },
  "de": {
    "score": "Ergebnis",
    "worst": "Sehr schlecht",
    "worse": "Ziemlich schlecht",
    "bad": "Schlecht",
    "good": "Gut"
  }
}
</i18n>
