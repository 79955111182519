<template>
  <div>
    <v-dialog v-if="confirmDelete" v-model="confirmDelete" max-width="360">
      <v-card>
        <v-card-title>{{ $t("delete user") }}?</v-card-title>
        <v-card-text v-if="deleteError == null">
          {{ $t("delete-message", { name: user.email }) }}
        </v-card-text>
        <v-card-text v-if="deleteError != null">
          <span class="error">{{ deleteError }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete = false" :disabled="loading" text>
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="deleteUserConfirmed"
            :disabled="loading || deleteError != null"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="confirmDisable2fa"
      v-model="confirmDisable2fa"
      max-width="360"
    >
      <v-card>
        <v-card-title>{{ $t("2fa-disable") }}?</v-card-title>
        <v-card-text v-if="disable2daError == null">
          {{ $t("2fa-disable-message", { name: user.email }) }}
        </v-card-text>
        <v-card-text v-if="disable2daError != null">
          <span class="error">{{ disable2daError }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDisable2fa = false" :disabled="loading" text>
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="disableUser2faConfirmed"
            :disabled="loading || disable2daError != null"
          >
            {{ $t("disable") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card outlined>
      <validation-observer ref="obs" v-slot="{ errors, invalid }">
        <v-form onSubmit="return false;">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="first_name"
                  :name="$t('first name')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.first_name"
                    name="first_name"
                    :label="$t('first name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="last_name"
                  :name="$t('last name')"
                  rules="required"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.last_name"
                    :name="last_name"
                    :label="$t('last name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="phone"
                  :name="$t('phone')"
                  rules="phone"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.phone"
                    name="phone"
                    :label="$t('phone')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                    @change="
                      user.phone =
                        dirty && valid ? formatPhoneNumber($event) : $event
                    "
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="mobile"
                  :name="$t('mobile')"
                  rules="phone"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.mobile"
                    name="mobile"
                    :label="$t('mobile')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                    @change="
                      user.mobile =
                        dirty && valid ? formatPhoneNumber($event) : $event
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="email"
                  :name="$t('email')"
                  rules="required|max:50|email"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.email"
                    name="email"
                    :label="$t('email')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                    maxlength="50"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6" v-if="updatePassword || id == null">
                <validation-provider
                  vid="password"
                  :name="$t('password')"
                  :rules="id == null ? 'required|max:50' : 'max:50'"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    :label="$t('password')"
                    name="password"
                    v-model="user.password"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :disabled="!isAdmin"
                    :class="classes"
                    @click:append="showPassword = !showPassword"
                    :clearable="!isMobile"
                    required
                    autocomplete="none"
                  />
                </validation-provider>
                <password-score v-model="score" :password="user.password" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="type"
                  :name="$t('user type')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-model="user.type"
                    name="type"
                    :items="types"
                    item-text="name"
                    item-value="code"
                    :label="$t('user type')"
                    :hint="typeHint"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                    persistent-hint
                  />
                </validation-provider>
                <v-banner icon="mdi-information" single-line tile>
                  {{ $t("type-notes") }}
                </v-banner>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="two_factor_required"
                  :name="$t('2fa-required')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-switch
                    v-model="user.two_factor_required"
                    name="two_factor_required"
                    :label="$t('2fa-required')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row align="baseline">
              <v-col cols="6">
                <validation-provider
                  vid="api_roles"
                  :name="$t('permissions')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-show="user.type == 'restricted'"
                    v-model="user.api_roles"
                    name="api_roles"
                    :items="apiRolesChoices"
                    item-text="description"
                    item-value="code"
                    :label="$t('permissions')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                    multiple
                    chips
                    deletable-chips
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="contact_roles"
                  :name="$t('contact types')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-model="user.contact_roles"
                    name="contact_roles"
                    :items="contactRolesChoices"
                    item-text="description"
                    item-value="code"
                    :label="$t('contact types')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!isAdmin"
                    :class="classes"
                    multiple
                    chips
                    deletable-chips
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="id != null && $store.getters.isStaff"
              color="staff"
              :disabled="loading || !isAdmin"
              @click="disableUser2FA()"
              text
            >
              {{ $t("2fa-disable") }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="id != null && $store.getters.isStaff"
              color="staff"
              :disabled="loading || invalid"
              @click="impersonate()"
              text
            >
              <v-icon left>mdi-login-variant</v-icon>
              {{ $t("impersonate") }}
            </v-btn>
            <v-btn
              v-if="id != null"
              :disabled="loading || !isAdmin"
              :loading="loadingDelete"
              @click="deleteUser()"
              text
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn
              v-if="id == null"
              :disabled="loading"
              :loading="loading"
              @click="$emit('userCanceled')"
              text
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-if="id != null"
              color="primary"
              :disabled="loading || invalid || !isAdmin"
              :loading="loading"
              @click="updateUser()"
              text
            >
              {{ $t("update") }}
            </v-btn>
            <v-btn
              v-if="id == null"
              color="primary"
              :disabled="loading || invalid || !isAdmin || score < 4"
              :loading="loading"
              @click="createUser()"
              text
            >
              {{ $t("create") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PasswordScore from "@/components/basics/PasswordScore";
import randomString from "@/utils/RandomString";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import forceRerenderApp from "@/utils/mixins/forceRerenderApp";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "UserForm",
  props: {
    id: {
      type: Number,
      default: null,
    },
    first_name: {
      type: String,
      default: null,
    },
    last_name: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
    mobile: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "restricted",
    },
    two_factor_required: {
      type: Boolean,
      default: false,
    },
    api_roles: {
      type: Array,
      default: () => [],
    },
    contact_roles: {
      type: Array,
      default: () => [],
    },
    password: {
      type: String,
      default: null,
    },
    apiRolesChoices: {
      type: Array,
      default: () => [],
    },
    contactRolesChoices: {
      type: Array,
      default: () => [],
    },
    updatePassword: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PasswordScore,
  },
  mixins: [formatPhoneNumber, forceRerenderApp, showErrors, isMobile],
  data() {
    return {
      confirmDelete: false,
      deleteError: null,
      confirmDisable2fa: false,
      disable2daError: null,
      loading: false,
      loadingDelete: false,
      score: 0,
      showPassword: false,
      types: [
        {
          code: "admin",
          name: this.$i18n.t("admin-name"),
          description: this.$i18n.t("admin-description"),
        },
        {
          code: "full",
          name: this.$i18n.t("full-name"),
          description: this.$i18n.t("full-description"),
        },
        {
          code: "restricted",
          name: this.$i18n.t("restricted-name"),
          description: this.$i18n.t("restricted-description"),
        },
      ],
      user: {
        id: this.id,
        first_name: this.first_name,
        last_name: this.last_name,
        phone: this.phone,
        mobile: this.mobile,
        email: this.email,
        type: this.type,
        two_factor_required: this.two_factor_required,
        api_roles: this.api_roles,
        contact_roles: this.contact_roles,
        password: this.id == null ? this.randomPassword() : this.password,
      },
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    typeHint() {
      for (let i = 0; i < this.types.length; i++) {
        if ((this.user.type || this.type) == this.types[i].code)
          return this.types[i].description;
      }
    },
  },
  watch: {
    id() {
      this.readUser();
    },
  },
  methods: {
    emitAll() {
      for (var prop in this.user) {
        this.$emit("update:" + prop, this.user[prop]);
      }
    },
    randomPassword() {
      return randomString(12);
    },
    readUser() {
      if (this.id != null) {
        var that = this;
        this.loading = true;
        this.$http
          .get("persons/" + this.id)
          .then((response) => {
            that.user = { ...response.data };
            that.emitAll();
          })
          .catch((error) => {
            if (error.invalid()) {
              that.$store.commit("setSystemError", {
                msg: error.message,
                title: error.title,
              });
            }
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    createUser() {
      if (this.id == null) {
        var that = this;
        this.loading = true;
        this.$http
          .post("persons", {
            ...this.user,
            welcome: true,
          })
          .then((response) => {
            that.user = response.data;
            that.$emit("userCreated");
          })
          .catch((error) => {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    updateUser() {
      if (this.id != null) {
        var that = this;
        this.user.phone = this.user.phone
          ? this.formatPhoneNumber(this.user.phone)
          : this.user.phone;
        this.loading = true;
        this.$http
          .patch("persons/" + this.id, this.user)
          .then((response) => {
            that.user = { ...response.data };
            that.emitAll();
            that.$emit("userUpdated");
          })
          .catch((error) => {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    deleteUser() {
      this.deleteError = null;
      this.confirmDelete = true;
    },
    deleteUserConfirmed() {
      if (this.id != null) {
        var that = this;
        this.loadingDelete = true;
        this.$http
          .delete("persons/" + this.user.id)
          .then((response) => {
            that.confirmDelete = false;
            that.deleteError = null;
            that.$emit("userDeleted");
          })
          .catch((error) => {
            that.deleteError = error.message;
          })
          .finally(function () {
            that.loadingDelete = false;
          });
      }
    },
    disableUser2FA() {
      this.disable2daError = null;
      this.confirmDisable2fa = true;
    },
    disableUser2faConfirmed() {
      var that = this;
      if (that.loading === true) return;
      that.loading = true;
      that.$http
        .put(
          "login/signoff",
          {
            email: that.user.email,
          },
          { disableDefaultErrorHandler: true }
        )
        .then((response) => {
          that.$snotify.success(that.$t("ConfiramtionMessage2faDeactivated"));
        })
        .catch(function (error) {
          that.$snotify.info(
            that.$t("ConfiramtionMessage2faAlreadyDeactivated")
          );
        })
        .finally(function () {
          that.confirmDisable2fa = false;
          that.loading = false;
        });
    },
    impersonate() {
      var that = this;
      this.loading = true;
      this.$http
        .post("login", {
          number: this.$store.state.session.number,
          email: this.user.email,
        })
        .then(function (response) {
          that.$store.commit("login", response.data);
          // re-render App and remove old components
          that.forceRerenderApp();
          that.$http
            .get("profile")
            .then(function (response) {
              that.$store.commit("setSession", response.data);
              that.$router.push({ name: "home" });
            })
            .catch(function (error) {});
        })
        .catch(function (error) {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.readUser();
  },
};
</script>

<i18n>
{
  "en": {
    "delete": "Delete",
    "disable": "Disable",
    "update": "Update",
    "create": "Create",
    "cancel": "Cancel",
    "delete user": "Delete user",
    "delete-message": "Do you realy want to delete user {name}?",
    "permissions": "Permissions",
    "contact types": "Contact types",
    "admin-name": "Administrator",
    "full-name": "Full permission user",
    "restricted-name": "Restricted user",
    "admin-description": "The user has the same rights as the main user of the customer account.",
    "full-description": "The user has assigned all available rights.",
    "restricted-description": "The user only has the assigned rights.",
    "first name": "First name",
    "last name": "Last name",
    "phone": "Phone",
    "mobile": "Mobile",
    "email": "Email",
    "user type": "User type",
    "password": "Password",
    "impersonate": "impersonate",
    "2fa-required": "Two-Factor Authentication required",
    "2fa-disable": "Disable Two-Factor",
    "2fa-disable-message": "Do you really want to disable Two-Factor Authentication for user {name}?",
    "ConfiramtionMessage2faDeactivated": "Two-Factor Authentication successfully disabled.",
    "ConfiramtionMessage2faAlreadyDeactivated": "Two-Factor Authentication is already disabled.",
    "type-notes": "Please note the information in the help."
  },
  "de": {
    "delete": "Löschen",
    "disable": "Deaktivieren",
    "update": "Aktualisieren",
    "create": "Anlegen",
    "cancel": "Abbrechen",
    "delete user": "Benutzer löschen",
    "delete-message": "Wollen Sie den Benutzer {name} wirklich löschen?",
    "permissions": "Rechte",
    "contact types": "Kontakt Arten",
    "admin-name": "Administrator",
    "full-name": "Voll authorisierter Benutzer",
    "restricted-name": "Eingeschränkter Benutzer",
    "admin-description": "Der Benutzer hat die selben Rechte wie der Hauptbenutzer des Kundenkontos.",
    "full-description": "Der Benutzer hat alle verfügbaren Rechte zugewiesen.",
    "restricted-description": "Der Benutzer hat nur die zugewiesenen Rechte.",
    "first name": "Vorname",
    "last name": "Nachname",
    "phone": "Telefon",
    "mobile": "Mobile",
    "email": "Email",
    "user type": "Benutzertyp",
    "password": "Passwort",
    "impersonate": "imitieren",
    "2fa-disable": "Zwei Faktor deaktivieren",
    "2fa-required": "Zwei Faktor Authentifizierung erforderlich",
    "2fa-disable-message": "Wollen Sie die Zwei Faktor Authentifizierung für den Benutzer {name} wirklich deaktivieren?",
    "ConfiramtionMessage2faDeactivated": "Zwei Faktor Authentifizierung erfolgreich deaktiviert.",
    "ConfiramtionMessage2faAlreadyDeactivated": "Zwei Faktor Authentifizierung ist bereits deaktiviert.",
    "type-notes": "Bitte beachten Sie die Hinweise in der Hilfe."
  }
}
</i18n>
