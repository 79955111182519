import { parsePhoneNumberFromString, formatIncompletePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

export default {
  methods: {
    formatPhoneNumber: function (number, countryCode, local, removeSpaces) {
      countryCode = countryCode || 'CH';
      local = local || false;
      removeSpaces = removeSpaces || false;
      try {
        var phoneNumber = parsePhoneNumberFromString(number, countryCode);
        if (phoneNumber && phoneNumber.isValid() && local)
          if (removeSpaces) return phoneNumber.formatNational().replace(/\s/g, "")
          else return phoneNumber.formatNational()
        else if (phoneNumber && phoneNumber.isValid())
          if (removeSpaces) return phoneNumber.formatInternational().replace(/\s/g, "")
          else return phoneNumber.formatInternational()
        else
          if (removeSpaces) return number.replace(/\s/g, "")
          else return number
      } catch (error) {
        return number;
      }
    }
  }
};
